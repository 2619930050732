<template>
  <b-container>
    <b-modal id="modal-center-255" centered title="Select Action">
      <b-list-group>
        <b-list-group-item @click="goToAppointment()">
          View Appointment Details
        </b-list-group-item>
        <b-list-group-item @click="creditAppointment()">
          Credit Appointment
        </b-list-group-item>
        <b-list-group-item @click="goToPatientDetails()">
          Reschedule Appointment
        </b-list-group-item>
        <b-list-group-item >
         <AdmitPatient class="w-100"></AdmitPatient>
        </b-list-group-item>
        <b-list-group-item variant="danger" @click="cancelAppointment()">
          Cancel Appointment
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-row style="width:100%" class="mx-auto mt-2">
      <list-component style="width:100%" :doctype="doctype" :fields="fields" @selected="rowSelected" />
    </b-row>
  </b-container>
</template>

<script>

import ListComponent from '@/views/components/doctype-list/ListComponent.vue'
import AdmitPatient from "./components/AdmitPatientModal.vue"
import { creditedTurnUp, addPatientToQueue, updateAppointmentStatus } from './availability'
export default {
  name: 'Patients',
  components: { ListComponent, AdmitPatient },
  data() {
    return {
      doctype: 'Patient Appointment',
      title: 'Appointment List',
      subTitle: '',
      fields: ['name', 'title', 'patient_name', 'status', 'appointment_date', 'service_unit'],
      id: '',
    }
  },mounted(){
    this.setTitle("Appointment List")
  },
  methods: {
    async creditAppointment() {
      const result = await creditedTurnUp(this.id)
      const result2 = await addPatientToQueue(this.id)
      this.$bvModal.hide('modal-center-255')
      this.show_alert("Appointment Credited")
    },
    goToAppointment() {
      const { id } = this
      this.$router.push({ name: 'view-appointment', params: { id } })
    },
    goToPatientDetails() {
      const { id } = this
      this.$router.push({ name: 'patient-view-actions', params: { id } })
    },
    async cancelAppointment() {
      const result = await updateAppointmentStatus(this.id, "Closed")
      this.show_alert("Appointment closed")
    },


    rowSelected(item) {
      if (item && item.length) {
        this.id = item[0].name
        this.$bvModal.show('modal-center-255')
      }
    },
  },
}
</script>

<style></style>
