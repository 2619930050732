<template>
    <div>
      <span style="margin-right:2px;" variant="primary" @click="admitEmergencyPatient">Admit Emergency Patient</span>
  
      <b-modal v-model="admitEmergencyModalVisible" title="Admit Emergency Patient" @ok="submitEmergencyAdmission">
        <b-form>
          <b-form-group label="Medical Department" label-for="medical_department" required>
            <!-- Use the component directly in the template -->
            <doctype-link
              @selected="handleMedicalDepartmentSelected"
              :doctype="'Medical Department'"
              :filters="medicalDepartmentFilters"
            ></doctype-link>
          </b-form-group>
  
          <b-form-group label="Healthcare Service Unit" label-for="service_unit" required>
            <!-- Use the component directly in the template -->
            <doctype-link
              @selected="handleServiceUnitSelected"
              :doctype="'Healthcare Service Unit'"
              :filters="serviceUnitFilters"
            ></doctype-link>
          </b-form-group>
  
          <b-form-group label="Bed" label-for="bed" required>
            <!-- Use the component directly in the template -->
            <doctype-link
              @selected="handleBedSelected"
              :doctype="'Healthcare Service Unit'"
              :filters="bedFilters"
            ></doctype-link>
          </b-form-group>
        </b-form>
      </b-modal>
    </div>
  </template>
  
  <script>

  import { admitEmergencyPatient, emergency,} from '../availability'
  export default {
    data() {
      return {
        admitEmergencyModalVisible: false,
        selectedMedicalDepartment: null,
        selectedServiceUnit: null,
        selectedBed: null,
        medicalDepartmentFilters: {
          name: ['not like', '%Farewell%'],
        },
        serviceUnitFilters: {
          name: ['not like', '%Farewell%'],
          is_group: 1,
        },
        bedFilters: {
          is_group: 0,
          name: ['like', '%BED%'],
          occupancy_status: 'Vacant',
        },
      };
    },
    methods: {
      admitEmergencyPatient() {
        // Set the initial values for the modal
        this.selectedMedicalDepartment = null;
        this.selectedServiceUnit = null;
        this.selectedBed = null;
        this.admitEmergencyModalVisible = true;
      },
      async submitEmergencyAdmission() {
        const results = await admitEmergencyPatient(this.selectedMedicalDepartment, this.doctype.name, this.selectedBed);
  
        this.doctype.is_emergency = 1;
        this.doctype.turned_up = 1;
        this.doctype.doctype = 'Patient Appointment';
        emergency(this.doctype.name).then(() => {
          this.refresh = this.$set(this, 'refresh', `${new Date()}`);
        });
        addPatientToQueue(this.doctype.name);
        this.admitEmergencyModalVisible = false;
      },
      handleMedicalDepartmentSelected(selectedValue) {
        // Handle the selected value from DoctypeLink component for Medical Department
        this.selectedMedicalDepartment = selectedValue;
      },
      handleServiceUnitSelected(selectedValue) {
        // Handle the selected value from DoctypeLink component for Healthcare Service Unit
        this.selectedServiceUnit = selectedValue;
      },
      handleBedSelected(selectedValue) {
        // Handle the selected value from DoctypeLink component for Bed
        this.selectedBed = selectedValue;
      },
    },
  };
  </script>
  